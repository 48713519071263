import React from "react";
import Link from "next/link";
import Image from "next/image";

import HeadTitle from "components/HeadTitle";

import { Container, Heading, ErrorDescription, StyledLink } from "../styles/404ErrorPage.styled";
import { Icon } from "components/SvgIcons";
import { SocialNetworks, LinkWrap } from "styles/SocialNetworks.styled";

export default function Custom404() {
  return (
    <>
      <HeadTitle title={"Error 404"} />
      <Container isPageError="error">
        <div>
          <Image src="/images/img404ErrorMobile.png" width={380} height={255} quality={100} />
          <Heading>Ooops, that page is gone</Heading>
          <ErrorDescription>
            The page you are looking for might have been removed had its name changed or is temporarily unavailable.
            Check out the{" "}
            <Link href="/" passHref>
              <StyledLink>home page</StyledLink>
            </Link>{" "}
            instead, it is usually nicer than this page.
          </ErrorDescription>
        </div>
        <SocialNetworks>
          <LinkWrap href="#" target="_blank" aria-label="instagram">
            <Icon icon="instagram" />
          </LinkWrap>
          <LinkWrap href="#" target="_blank" aria-label="twitter">
            <Icon icon="twitter" />
          </LinkWrap>
          <LinkWrap href="#" target="_blank" aria-label="facebook">
            <Icon icon="fb" />
          </LinkWrap>
          <LinkWrap href="#" target="_blank" aria-label="pinterest">
            <Icon icon="pinterest" />
          </LinkWrap>
        </SocialNetworks>
      </Container>
    </>
  );
}
