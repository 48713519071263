import styled from "@emotion/styled";
import { fonts } from "styles/fonts";
import { colors } from "styles/colors";
import { SocialNetworks } from "styles/SocialNetworks.styled";

export const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 80px;
  max-width: 1304px;
  flex-grow: 1;
  text-align: center;
  background: ${colors.WHITE}; 
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  
  @media (max-width: 769px) {
      padding-top: 30px;
  }
  
  
  + footer{
    display: none;
  }
  ${SocialNetworks} {
    margin: auto 0 60px;
    .icon {
      width: 48px;
      height: 48px;
      fill: ${colors.GREY};
      stroke: none;
      &:hover {
        fill: ${colors.RED};
      }
      text-indent: 0;
      &.icon-message,
      &.icon-email {
        stroke: ${colors.GREY};
        fill: none;
        &:hover {
          stroke: ${colors.RED};
        }
      }
    }
    
`;

export const Heading = styled.h1`
  color: ${colors.GREY};
  font-size: 36px;
  font-family: ${fonts.abhayaBold};
`;

export const ErrorDescription = styled.p`
  color: ${colors.GREY_SEMIDARK};
  font-size: 16px;
  font-family: ${fonts.latoRegular};
  max-width: 487px;
  margin: auto;
`;

export const StyledLink = styled.a`
  color: ${colors.RED};
`;

export const StyledPointerLink = styled.a`
  color: ${colors.RED};
  cursor: pointer;
`;
