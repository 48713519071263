import styled from "@emotion/styled";
import { colors } from "styles/colors";

export const LinkWrap = styled.a`
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 0 8px;
  line-height: 0.7em;
`;

export const SocialNetworks = styled.div`
  margin: 8px -8px;
  @media (min-width: 769px) {
    margin: 11px -8px;
  }
  .icon {
    width: 24px;
    height: 24px;
    fill: ${colors.GREY};
    stroke: none;
    transition: all 0.3s ease-in-out;
    &:hover {
      fill: ${colors.RED};
    }
    text-indent: 0;
    &.icon-message,
    &.icon-email {
      stroke: ${colors.GREY_DARK};
      fill: none;
      &:hover {
        stroke: ${colors.RED};
      }
    }

    &.user {
      fill: ${colors.GREY};
      &:hover {
        fill: ${colors.GREY_DARK};
      }
    }
  }
  &.user {
    @media (min-width: 769px) {
      margin-top: 14px;
    }
  }
`;
